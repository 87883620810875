<template>
  <div>
      <Tab :items="items" class="my-10">
      <template #icon="{ item }">
        <md-icon class="mr-3">{{ item.icon }}</md-icon>
      </template>
    </Tab>

    <router-view />
  </div>
</template>

<script>
import Tab from "@/components/atom/Tab";
import data from "@/data/communication/data";
export default {
    components: {
        Tab
    },
    data() {
        return {
             items: data.interactionTabNavigation,
        }
    }
}
</script>

<style>

</style>